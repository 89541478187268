import React from 'react'

import Layout from '../components/layout'
import Blurb from '../components/Blurb'
import SEO from '../components/SEO';

const FaqPage = () => (
  <Layout>
    <SEO title="FAQ"/>
    <h2>WHY HERE?</h2>
    <Blurb>
      We liked the idea of getting married in New York, our home state. We liked the idea of
      doing something outside the city. We liked the idea of a (minor) destination wedding,
      where everyone could spend a few days together. And mostly we just loved the Inn after
      we happened across it in January, 2018. We thought it would be a perfect place for us —
      pretty and relaxed but neither too remote nor too rustic. (We are not especially rustic
        people.) Also, the food is really good.
    </Blurb>
    <h2 id="dress">WHAT SHOULD WE WEAR?</h2>
    <Blurb>
      Short answer: Cocktail attire for the wedding. Somewhat longer answer: Look spiffy but
      not formal, and keep in mind both the ceremony and the reception will be outdoors and on grass.
      That should factor into your footwear choices. Also, remember that it will be cooler in the
      mountains than in the city, especially at night.
    </Blurb>
    <h2>ARE KIDS INVITED?</h2>
    <Blurb>
      Amelia and Quinn Strasser, Spencer Oxfeld, and Cora Keidel, absolutely. Otherwise,
      unfortunately not. We want you all to be able to have grown-up fun. Let us know if you need a babysitter in
      Tannersville, and we can work with our planner to make arrangements.
    </Blurb>
    <h2>BY THE WAY, HOW’D YOU TWO MEET?</h2>
    <Blurb>
      The old-fashioned way: on the internet. Several years ago, when Will was living
      in Philadelphia, he was visiting New York and, bored, swiping Tinder. He and Jesse
      matched and met for a drink. Then Jesse went off to see a play and Will went back to
      Philadelphia, and that was that. In the fall of 2016, after Will had moved to New York,
      Facebook decided to suggest to Will that Jesse was someone he might know. He did! And
      so we chatted and then met for drinks that, when we discovered we both liked Tito’s
      martinis that are not actually martinis, quickly turned into dinner. Three years and
      a week later, we’ll be getting married.
    </Blurb>
    <h2 id="benno">WHO IS THIS ‘BEN’ YOU TALK ABOUT?</h2>
    <Blurb>
      Benno <a href="https://www.instagram.com/p/BisBfUNnJm_/" target="_blank" rel="noopener noreferrer">Oxfeld</a>-
      <a href="https://www.instagram.com/p/Bl3RLaeFBFW/" target="_blank" rel="noopener noreferrer">Strasser</a> is our
      car. (We’re not the kind who would anthropomorphize a pet, obviously.) He’s a candy-apple-red 1992
      BMW 325i convertible whom we adopted from outside Rochester in the spring of 2018. We love him very much.
    </Blurb>
  </Layout>
)

export default FaqPage
